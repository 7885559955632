// a custom axios to centralise key API procedures i.e. add token to all requests, add filters to specific requests, deal centrally with error handling etc.
import axios, { isCancel } from 'axios';

import { authRedirectCheck } from '../auth/authRedirectCheck';
import { errorSnackBar } from '../error';

const host = `https://${process.env.REACT_APP_BACKEND_ENDPOINT_HOST}`;

const customAxios = (apiPath, enqueueSnackbar, isAuthCheckNeeded = true) => {
  const API = `${host}/${apiPath}`;

  // axios instance for making requests
  // all requests have token added to the header
  const axiosInstance = axios.create({
    baseURL: API,
  });

  // skip auth check if we dont need to check if a user is authenticated (e.g auth app)
  if (isAuthCheckNeeded) {
    axiosInstance.interceptors.request.use((config) =>
      authRedirectCheck(config),
    );
  }

  // RESPONSE MIDDLEWARE
  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      // if any api errors than show error snackbar
      if (enqueueSnackbar && !isCancel(err)) {
        errorSnackBar(enqueueSnackbar, err);
      }
      throw err;
    },
  );

  return axiosInstance;
};

export default customAxios;
