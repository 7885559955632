import { omit } from 'lodash';
import { useCallback, useState } from 'react';

import { type HandleError, type HandleWarning } from '../types';

const useForm = () => {
  const [error, setError] = useState<Record<string, any>>({});
  const [warning, setWarning] = useState<Record<string, any>>({});

  const handleError: HandleError = useCallback(
    ({ errorCheck, type, message }) => {
      if (errorCheck) {
        const errorObject = { [type]: { message } };
        setError((prevError) => ({ ...(prevError || {}), ...errorObject }));
      } else {
        setError((prevError) => omit(prevError, type));
      }
    },
    [],
  );

  const handleWarning: HandleWarning = useCallback(
    ({ warningCheck, type, message }) => {
      if (warningCheck) {
        const warningObject = { [type]: { message } };
        setWarning((previousWarning) => ({
          ...(previousWarning || {}),
          ...warningObject,
        }));
      } else {
        setWarning((previousWarning) => omit(previousWarning, type));
      }
    },
    [],
  );

  const isError = Object.keys(error).length > 0;

  return { warning, error, handleWarning, handleError, isError };
};

export default useForm;
