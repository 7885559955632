import { type PrimitiveAtom } from 'jotai/index';

import { type OrderType } from '../../../types';
import { tableStyles } from '../../../utils/table/tableStyles';
import { type SortObject, type SortOrder } from '../types';

import useTablePagination from './useTablePagination';
import useTableSorting from './useTableSorting';

const getSortField = (column: string, direction: OrderType) =>
  `${column}:${direction}`;

const usePagingTable = ({
  initialRowsPerPage = 50,
  initialSortColumn,
  initialSortOrder,
  sortStorageAtom,
}: {
  initialRowsPerPage?: number;
  initialSortColumn: string;
  initialSortOrder: OrderType;
  sortStorageAtom?: PrimitiveAtom<{
    sortOrder: SortOrder;
    sortColumn: string;
    sortObject: SortObject | null;
  }>;
}) => {
  const { sortOrder, sortColumn, handleSort } = useTableSorting({
    defaultSort: initialSortOrder,
    initialSortColumn,
    storageAtom: sortStorageAtom,
  });

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    resetPageToZero,
  } = useTablePagination({ initialRowsPerPage });

  return {
    resetPageToZero,
    tableProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      handleSort,
      page,
      rowsPerPage,
      tableClasses: tableStyles,
      sortOrder,
      sortColumn,
      serverSort: getSortField(sortColumn, sortOrder),
    },
  };
};

export default usePagingTable;
