import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { type InternalAxiosRequestConfig } from 'axios';

import AuthError from '../error/AuthError';

import { getTokenExpiry, redirectToAuth } from './helpers';

export const authRedirectCheck = async (
  config?: InternalAxiosRequestConfig,
) => {
  const { tokens } = await fetchAuthSession();

  if (!tokens) {
    redirectToAuth();
    return Promise.reject(new AuthError());
  }

  const { hasTokenExpired, idToken, accessToken } = getTokenExpiry(tokens);

  if (hasTokenExpired) {
    try {
      const { tokens } = await fetchAuthSession();
      if (config) {
        config.headers['x-api-key'] = tokens?.idToken?.toString();
        config.headers['x-api-access-key'] = tokens?.accessToken?.toString();
      }
      return config;
    } catch {
      signOut();
      return Promise.reject(new AuthError());
    }
  } else {
    if (config) {
      config.headers['x-api-key'] = idToken;
      config.headers['x-api-access-key'] = accessToken;
    }
    return config;
  }
};
