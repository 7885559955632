import { type Budget } from '@autone/openapi-budget';
import { customBaseQuery, encodeUrl } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

// Define a service using the base core URL and expected endpoints
export const budgetApi = createApi({
  reducerPath: 'budgetApi',
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    return customBaseQuery(args, api, extraOptions, 'v2/budget');
  },
  endpoints: (builder) => ({
    getBudgetUploads: builder.query<Budget[], { currentDate: string }>({
      query: ({ currentDate }) => ({
        url: encodeUrl({
          url: `/budgets?current_date={currentDate}`,
          variables: { currentDate },
        }),
        method: 'GET',
      }),
      transformResponse: (res: { budgets: Budget[] }) => res.budgets,
    }),
  }),
});

export const { useGetBudgetUploadsQuery } = budgetApi;
