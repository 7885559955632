import { UPDATE_CONFIG } from '@autone/ui';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getUserConfig } from '../utils/apis';

const useUserActions = () => {
  const dispatch = useDispatch();

  const fetchUserData = useCallback(async () => {
    const { data } = (await getUserConfig()) || {};

    dispatch(UPDATE_CONFIG(data));
  }, [dispatch]);

  return {
    fetchUserData,
  };
};

export default useUserActions;
