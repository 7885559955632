interface IAuthError {
  code: string;
  name: string;
}

class AuthError extends Error implements IAuthError {
  readonly code;
  constructor() {
    super('User not authenticated');
    this.code = '0001';
    this.name = 'AuthError';
  }
}

export const isAuthError = (error: unknown): error is IAuthError => {
  return (
    error instanceof AuthError ||
    (error instanceof Error && error.name === 'AuthError')
  );
};

export default AuthError;
