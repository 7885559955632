import { type PropsWithChildren } from 'react';

import { cn } from '../../tailwind';

const SearchBarSectionWrapper = ({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={cn(className, 'bg-white')}>{children}</div>
);

const SearchBarSectionHeader = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={cn(
        'px-4 py-[18px] flex justify-between items-center',
        className,
      )}
    >
      {children}
    </div>
  );
};

SearchBarSectionWrapper.Header = SearchBarSectionHeader;

export default SearchBarSectionWrapper;
