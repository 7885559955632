import { type User } from '@autone/openapi-core';
import { DEFAULT_PRICE_TYPE, formatPriceTypeData } from '@autone/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SELECT_PRICE_TYPE,
  UPDATE_PRICE_TYPE_OPTIONS,
} from '../redux/priceTypes';

import useLocalStorage from './useLocalStorage';

const usePriceTypeOptions = () => {
  const dispatch = useDispatch();

  // @ts-ignore can't type this as the store is not in the ui package
  const { config } = useSelector((state) => state.user) as { config: User };
  const { getLocalStorage } = useLocalStorage({
    key: 'selected-price-type',
    defaultValue: undefined,
  });

  useEffect(() => {
    if (!config) return;

    const {
      available_price_types: availablePriceTypes,
      default_price_type: defaultPriceType,
    } = config;

    const { priceOptions } = formatPriceTypeData(
      availablePriceTypes,
      defaultPriceType,
    );

    // default metric is always rrp for customers as it's a retail based app
    const defaultPriceObject = priceOptions?.find(
      (item) => item.id === DEFAULT_PRICE_TYPE,
    );

    dispatch(UPDATE_PRICE_TYPE_OPTIONS(priceOptions));
    dispatch(SELECT_PRICE_TYPE(getLocalStorage() ?? defaultPriceObject));
  }, [config]);
};

export default usePriceTypeOptions;
