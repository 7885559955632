import { atom, type PrimitiveAtom, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useMemo } from 'react';

import { type Column, type SortObject, type SortOrder } from '../types';

const useTableSorting = <TSortColumn>({
  defaultSort,
  initialSortColumn,
  storageAtom,
}: {
  defaultSort: SortOrder;
  initialSortColumn: TSortColumn;
  storageAtom?: PrimitiveAtom<{
    sortOrder: SortOrder;
    sortColumn: TSortColumn;
    sortObject: SortObject | null;
  }>;
}) => {
  const skipStorage = typeof storageAtom === 'undefined';

  const emptyStorageAtom = useMemo(() => atom(null), []) as PrimitiveAtom<any>;

  const [sortAtom, setSortAtom] = useAtom(storageAtom ?? emptyStorageAtom);

  const runHydration = !!(!skipStorage && sortAtom);

  const finalConfig = sortAtom
    ? sortAtom
    : {
        sortOrder: defaultSort,
        sortColumn: initialSortColumn,
        sortObject: null,
      };

  useHydrateAtoms([
    [runHydration ? storageAtom : emptyStorageAtom, finalConfig],
  ]);

  const { sortOrder, sortColumn, sortObject } = finalConfig;

  const handleSort = (column?: Column, key?: string) => {
    if (key && typeof column === 'object' && key in column) {
      if (column[key] === sortColumn) {
        setSortAtom({
          ...sortAtom,
          sortColumn: sortColumn,
          sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
        });
      } else {
        setSortAtom({
          ...sortAtom,
          sortColumn: column[key],
          sortObject: column,
        });
      }
    } else if (column === sortColumn) {
      setSortAtom({
        ...sortAtom,
        sortColumn: sortColumn,
        sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortAtom({
        ...sortAtom,
        sortOrder: sortOrder,
        sortColumn: column,
      });
    }
  };

  return {
    sortObject,
    sortOrder,
    sortColumn,
    handleSort,
  };
};

export default useTableSorting;
