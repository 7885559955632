export const tableStyles = {
  stickyLeft: {
    position: 'sticky' as const,
    left: 0,
    background: 'white',
    borderRight: `1px solid grey.100`,
    zIndex: '1',
  },
  stickyRight: {
    position: 'sticky' as const,
    right: 0,
    background: 'white',
    borderLeft: `1px solid grey.100`,
  },
  stickyHeaderLeft: {
    position: 'sticky' as const,
    left: 0,
    borderRight: `1px solid grey.100`,
    zIndex: '99',
  },
  stickyHeaderRight: {
    position: 'sticky' as const,
    right: 0,
    borderLeft: `1px solid grey.100`,
    zIndex: '99',
  },
  stickyFooter: {
    position: 'sticky' as const,
    left: 0,
    background: 'white',
    borderTop: `1px solid grey.100`,
    borderRight: `1px solid grey.100`,
  },
  footer: {
    borderTop: `1px solid grey.100`,
  },
  footerLabel: {
    borderTop: `1px solid grey.100`,
    borderRight: `1px solid grey.100`,
  },
  rows: {
    border: `1px solid grey.100`,
  },
};
